/* ------------ Variables ------------ */

$header-height : 64px;
$sidebar-width : 200px;

$theme-colors: (
    "2C2058": #2C2058,
    "180C44": #180C44,
    "E30047": #E30047,
    "242424": #242424,
    "E51D4F": #E51D4F,
    "AE3276": #AE3276,
    "F6F4FF": #F6F4FF,
    "FFFBFD": #FFFBFD
);

/* -------------------------------- */
/* ------------ Styles ------------ */
/* -------------------------------- */

body {
    margin: 0;
    padding: 0;
}

.custom-row-brand-list{
    height: 132px;
}
/* ------------------------  Backgroung ------------------------ */
@each $color, $color-code in $theme-colors {
    .bg-#{$color} {
        background-color: $color-code;
    }
}

/* ------------------------ Layout ------------------------ */

/* ------------ header ------------ */

.header {
    padding: 0 24px;
    z-index: 100;

    .logo {
        float: left;
        width: calc(#{$sidebar-width} - 2*24px);
        height: 31px;
        margin: 16px 24px 16px 0;
        // background: rgba(255, 255, 255, 0.2);
    }
}

.fixed-header {
    position: fixed;
    width: 100% !important;

    div.ant-menu-submenu-title {
        display: flex;
       align-items: center;
      }
}


/* ------------ sidebar ------------ */

.sidebar {
    // overflow: 'auto',
    margin-top: $header-height;
    left: 0;
    bottom: 0;
    z-index: 10;

    .logo {
        height: 32px;
        margin: 16px;
        // background: rgba(255, 255, 255, 0.2);
    }
}



.ant-layout-sider-zero-width-trigger {
    opacity: 0.6;
}

/* ------------ sidebar ------------ */

.page-wrapper {
    // margin-left: $sidebar-width;
    margin-top: $header-height;

    min-height: calc(100vh - #{$header-height});

    .content {
        padding: 30px 32px 0;

        .page-header {
            margin-bottom: 20px;

            .page-title {
                margin-bottom: 0;
            }
        }
    }
}

.footer {
    text-align: center;
}

/* ------------------------ End Layout ------------------------ */

.loader-section {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.center-div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.auth-card {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1);
}

/* -------- @media min-width -------- */

.auth-page{
    background-size: cover;
}

.auth-card .ant-card-body {
    padding: 0;
}

/* -------- @antd -------- */

.ant-upload-list{
    display: flex;
    flex-direction: row-reverse;
    float: left;
}

.ant-input[disabled]{
    color: rgba(0, 0, 0, 1);
}
.ant-upload-list-picture-card .ant-upload-list-item-actions,
.ant-upload-list-picture-card .ant-upload-list-item-info::before{
    display: none;
}

/* -------- @Ck-editor -------- */

.ck.ck-editor__main>.ck-editor__editable{
    height: 300px;
}

/* -------- role-permissions -------- */

.grid-container-role-permissions {
    display: grid;
    grid-template-columns: auto auto;
    // padding: 5px;
}

.grid-item-role-permissions {
    padding: 0px 0px 10px 0px;
}

/* -------- @media min-width -------- */

@media (min-width: 576px) {}

@media (min-width: 768px) {}


@media (min-width: 992px) {}

@media (min-width: 1200px) {}

/* -------- @media max-width -------- */

@media (max-width: 1199.99px) {}

@media (max-width: 991.99px) {
    .page-wrapper {
        margin-left: 0;
    }

    .header {
        .logo {
            // margin: unset;
        }
    }

    .grid-container-role-permissions {
        grid-template-columns: auto;
    }
}

@media (max-width: 767.99px) {

    .page-wrapper {
        .content {
            padding: 20px 20px 0;
        }
    }
}

@media (max-width: 575.99px) {}