.@{input-prefix-cls}:focus, .@{input-prefix-cls}-focused,
.@{input-number-prefix-cls}-focused,
// .@{select-prefix-cls}-focused .@{select-prefix-cls}-selector,
.@{select-prefix-cls}-focused:not(.@{select-prefix-cls}-disabled).@{select-prefix-cls}:not(.@{select-prefix-cls}-customize-input) .@{select-prefix-cls}-selector,
.@{picker-prefix-cls}-focused,
.@{input-prefix-cls}-affix-wrapper:focus, .@{input-prefix-cls}-affix-wrapper-focused
{
    border-color: @form-component-primary-color;
}

// .@{ant-prefix}-checkbox-disabled + span {
//     color: @text-color;
// }

// .@{radio-prefix-cls}-disabled + span {
//     color: @text-color;
// }